.layout-header{
    /* background: rgba(240, 240, 253,0.5); */
    padding: 8px 1.5em;
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
    backdrop-filter: blur(8px);
    z-index: 999;
    background: var(--theme-color-a);
}

.layout-header-bf-login{
    padding: 8px 1.5em;
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 999;
    background: var(--theme-color-a);

}

.layout-header-bf-login.active{
    background: linear-gradient(100deg, #2d86cae5, #2d86cade);
    backdrop-filter: blur(8px);
}

.layout-header-logo{
    max-height: 60px;
    max-width: 150px;
    /* border-radius: 50%; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-header-logo img{
    max-width: 100%;
    max-height: 100%;
}

.layout-section{
    width: 100%;
    min-height: 87vh;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.layout-social-section{
    position: fixed;
    top: 85%;
    right: 1em;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.layout-social-section>div{
    min-width: 2.5em;
    min-height: 2.5em;
    padding: 0.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #cae9ff5e;
    /* box-shadow: 0 0 2em 0.01em var(--theme-color-a-alpha); */
}

.layout-social-section svg{
    font-size: 2em;
}

.layout-inner-section{
    width: 80%;
}


.float-acts-item{
    position: fixed;
    background-color: white;
    box-shadow:  0 0 10px -5px grey;
    width: 100px;
    z-index: 1000;
    border-radius: 10px;
    right: 20px;
    bottom: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 8px;
    padding: 12px;
    cursor: pointer;
}

.float-acts-item .title{
    color: var(--theme-color-a);
    font-weight: bold;
    text-wrap: nowrap;
}

.float-notification{
    position: absolute;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    font-size: 0.9em;
    justify-content: center;
    align-items: center;
    color: white;
    background: var(--theme-color-a);
}
@media only screen and (min-width: 10px) {
    .layout-inner-section{
        width: 100% !important;
        padding: 1em;
    }
}
@media only screen and (min-width: 768px) {
    .layout-inner-section{
        width: 95% !important;
    }
}
@media only screen and (min-width: 1200px) {
    .layout-inner-section{
        width: 90% !important;
    }
}

/* ================================================================ */

.layout-header-content{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.layout-right-header ul{
    list-style: none;
    display: flex;
    gap: 2em;
}

.layout-right-header ul.small-menu{
    flex-direction: column;
    position: absolute;
    background: white;
    box-shadow: 0 0 1em 0.1em rgba(0, 0, 0, 0.1);
    padding: 1em;
    right: 1em;
    top: 2.5em;
    width: max-content;
    max-width: 15em;
    border-radius: 0.2em;
    gap: 1em;
    display: none;
}

.layout-right-header ul.small-menu>li>a{
    color: #2d86cae5;
    font-size: 1em;
}


.layout-right-header ul li a{
    font-size: 1.5em;
    color: white;
    transition: all 0.3s ease-in-out;
}

.layout-right-header ul li a:hover{
    color: var(--sec-color);
}


.layout-header-content-bf-login{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.layout-header-content-bf-login ul{
    list-style: none;
    display: flex;
    gap: 2em;
    padding-right: 1em;
}

.layout-header-content-bf-login ul li a {
    font-size: 1em;
    color: rgb(255, 255, 255);
    position: relative;
    padding: 0.5em 1em;
    overflow: hidden;
    display: flex;
    transition: all 0.4s ease-in-out;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: .5em;
    border-top-right-radius: .5em;
    /* border: 1px solid rgba(255, 255, 255, 1); */
}

.layout-header-content-bf-login ul li a::before {
    content: '';
    position: absolute;
    top: 100%; /* Start at the bottom */
    left: 100%; /* Start at the right */
    width: 100%;
    height: 100%;
    border-top-left-radius: 1em;
    background-color: white; /* Background color of the sliding element */
    transition: top 0.5s ease-in-out, left 0.3s ease-in-out;
    z-index: -9; /* Place behind the anchor element */
}

.layout-header-content-bf-login ul li a:hover::before,
.layout-header-content-bf-login ul li .active ::before {
    left: 0; /* Slide to left */
    top: 0; /* Slide to top */
}

.layout-header-content-bf-login ul li a:hover{
    color: black;
}
.layout-header-content-bf-login ul.small-menu{
    display: flex;
    width: max-content;
    flex-direction: column;
    position: absolute;
    top: 3em;
    right: 0;
    background: white;
    gap: 0.3em;
    border-radius: 0.2em;
    padding: 1em;
    box-shadow: 0 0 1em .3em rgba(0, 81, 255, 0.2);
}

.layout-header-content-bf-login ul.small-menu>li>a{
    color: var(--color-a);
    gap: 0.4em;
}

@media only screen and (min-width: 100px) {
    .layout-header-content-bf-login ul.lg-menu,
    .layout-right-header ul.lg-menu{
        display: none;
    }

    .layout-right-header ul.small-menu,
    .layout-header-content-bf-login ul.small-menu{
        width: 100%;
        max-width: 100%;
        right: 0;
        left: 0;
        top: 0;
        text-align: center;
    }

    .layout-header-content,
    .layout-header-content-bf-login{
        position: static;
    }

    .hamburger-div{
        display: flex;
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .layout-header-content-bf-login ul.lg-menu,
    .layout-right-header ul.lg-menu{
        display: flex;
    }
    .layout-header-content-bf-login{
        min-width: 22em;
    }

    .hamburger-div,
    .layout-right-header ul.small-menu{
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    
}


/* ====================================================================== */

.layout-footer{
    width: 100%;
    background: rgb(230, 230, 230);
    padding: 1em;
}

/* ================================================================================== */

.footer-section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5em 2em;
    background: rgb(243, 243, 243);

}

.footer-inner-section{
    width: 80%;
    display: flex;
    gap: 2em;
    justify-content: center;
}
.footer-column.logo-column{
    width: 100%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    
    gap: 1em;
}

.footer-column{
    width:50%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.footer-column ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.footer-column.logo-column ul{
    flex-direction: row;
    gap: 1em;
}

.footer-column ul>li>a{
    display: flex;
    gap: 1em;
}

.footer-column ul>li>a>svg{
    min-width: 1em;
    font-size: 1.2em;
    color: var(--color-a);
}

@media only screen and (min-width: 10px) { 
    .footer-inner-section{
        width: 100%;
        flex-wrap: wrap;
    }
    .footer-column{
        width: 100%;
        max-width: 100%;
    }
    .footer-column.logo-column{
        max-width: 100%;
    }
}

@media only screen and (min-width: 768px) { 
    .footer-inner-section{
        width: 90%;
        
    }
    .footer-column{
        width: 100%;
        max-width: 20%;
    }
    .footer-column.logo-column{
        max-width: 30%;
    }
}

@media only screen and (min-width: 1200px) { 
    .footer-inner-section{
        width: 80%;
        flex-wrap: nowrap;
    }
}