

.chat-wrapper-div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chat-wrapper{
    width: 100%;
    max-width: 80em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(246, 246, 248);
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
}


.chat-messages-list{
    width: 100%;
    height: 300px;
    padding: 2em;
    display: flex;
    gap: 1em;
    flex-direction: column;
    overflow-y: auto;
    white-space: pre-line;
}


.chat-compose{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: rgb(246, 246, 248);
}

.chat-compose> textarea{
    flex-grow: 1;
    border: none;
    background: white;
    font-size: 1.1em;
    padding: 1em;
    outline: none;
    resize: none;
    border-radius: 10px;
    margin: 10px;
    border: 1px solid rgb(205, 205, 211);
}

.chat-compose textarea::placeholder{
    color: rgba(0,0,0,red);
    font-weight: lighter;
}

.chat-compose-actions-right{
    min-width: 80px;
    padding: auto 10px;
}

.chat-compose-actions-right>div{
    display: flex;
    align-items: center;
    justify-content: center;

}

.chat-compose-actions-right>div>svg{
    transform: rotate(330deg);
    font-size: 1.5em;
}

.chat-start-conv{
    text-align: center;
    padding: 25px;
    font-size: 1.5em;
    color: grey;
}
.reciever-div-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.reciever-div{
    padding: 1em;
    background: #7a6af0;
    color: white;
    border-radius: 0.3em;
    max-width: 90%;
    width: fit-content;
}

.sender-div-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.sender-time,
.reciever-time{
    font-size: 0.7em;
    color: grey;
}

.sender-div{
    padding: 1em;
    background: #e1defd;
    color: black;
    border-radius: 0.3em;
    width: fit-content;
    max-width: 90%;
}



.details-chat-inner-section{
    max-width: 80em;
    width: 100%;
    background: white;
    border-radius: 0.5em;
    border: 1px solid rgba(0,0,0,0.1);
    /* background: #dee5fd; */
    /* box-shadow: 0 0 2em 0.01em #dee5fd95 ; */

}

.details-chat-msg-div{
    width: 100%;
    height: 25em;
    
    overflow-y: scroll;
    padding: 2em;
    display: flex;
    gap: 1em;
    flex-direction: column;

}

.chat-messages-list::-webkit-scrollbar {
    width: 5px;
}

.chat-messages-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.5em rgba(0,0,0,0.05); 
    background: rgba(251, 251, 251, 1);
    border-radius: 10px;
}

.chat-messages-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
    background: rgba(221, 221, 221, 1);
}

.reciever-div-section{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.reciever-div{
    padding: 1em;
    background: #6a83f0;
    color: white;
    border-radius: 0.3em;
    max-width: 90%;
    width: fit-content;
}

.sender-div-section{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.sender-div{
    padding: 1em;
    background: #dee5fd;
    color: black;
    border-radius: 0.3em;
    max-width: 90%;
}
