.bt-toast-div{
    position: fixed;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    padding: 2em 0;
    z-index: 1000;

}

.bt-toast{
    
    position: fixed;
    max-width: 400px;
    min-width: 300px;
    left: 50%;
    border-radius: 10px;
    padding:1.5em;
    translate: -50%;
    align-items: center;
    display: flex;
    justify-content: center;
    /* animation-name: example; */
    /* animation-duration: .5s; */
}
.bt-toast.type-error{
    background: rgb(231, 49, 49);
}


.bt-toast.type-success{
    background: rgb(10, 167, 96);
}
/* 
@keyframes example {
    from {top: 10%;}
    to {top: 13%;}
  } */

@media only screen and (min-width: 10px) {
    .bt-toast{
        max-width: 100%;
    }
}
@media only screen and (min-width: 768px) {
    .bt-toast{
        max-width: 400px;
    }
}
@media only screen and (min-width: 1200px) {
    .bt-toast{
        
    }
}