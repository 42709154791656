.login-section{
    width: 100%;
    height: 100vh;
    /* background: rgba(251, 251, 251, 1); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-inner-container{
    width: 30%;
    padding: 2em;
    padding-bottom: 5em;
    /* background: rgb(255, 255, 255); */
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.signup-inner-container a{
    text-decoration: none;
    text-align: center;
    width: fit-content;
}

@media only screen and (min-width: 10px) {
    .login-inner-container{
        width: 90%;
    }
}
@media only screen and (min-width: 768px) {
    .login-inner-container{
        width: 50%;
    }
}
@media only screen and (min-width: 1200px) {
    .login-inner-container{
        width: 30%;
    }
}