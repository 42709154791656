.dashboard{
    display: flex;
    justify-content: center;
    padding:100px 10px;
}
.dash-menu{
    display: flex;
    /* flex-direction: column; */
    gap: 0.5em;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.dash-menu>div{
    background-color: rgb(250, 250, 250);
    color: black;
    font-weight: 400;
    margin:10px;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    color: var(--pri-color);
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: 0 0 1em 0.01em var(--pri-color-alpha) inset;
    height: 150px;
    text-align: center;
    cursor: pointer;
}

/* .dash-menu>div:nth-child(1){
    background: rgb(255, 240, 213);
}

.dash-menu>div:nth-child(2){
    background: rgb(255, 231, 225);
}

.dash-menu>div:nth-child(3){
    background: rgb(251, 255, 213);
}

.dash-menu>div:nth-child(4){
    background: rgb(235, 255, 245);
}

.dash-menu>div:nth-child(5){
    background: rgb(226, 226, 255);
} */

@media only screen and (min-width: 10px){
    .dash-menu>div{

    }
}

@media only screen and (min-width: 500px){
    .dash-menu>div{
        max-width: 200px;
    }
}

@media only screen and (min-width: 600px){
    .dash-menu>div{
        max-width: 250px;
        min-width: 250px;
    }
}
@media only screen and (min-width: 700px){
    .dash-menu>div{
        max-width: 300px;
    }
}