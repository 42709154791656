

.spouse-linked-div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ebebeb;
    border-radius: 0.5em;
    padding: 0.5em 1em;
}

.spouse-linked-div>span{
    font-size: 1.2em;
}