*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --theme-color-a: #2d87ca;
  --theme-color-a-alpha: #004bad2d;
  --pri-color: #4592CE;
  --pri-color-active: #b1a296d0;
  --pri-color-alpha: #4593ce1c;
  --sec-color: #FE5757;
  --sec-color-alpha: #fe57572f;
  --sec-color-active: #fe5757e5;


  --color-a: #003A5B;

}

@font-face {
  font-family: quicksand;
  src: url('./assets/fonts/Quicksand/Quicksand-VariableFont_wght.ttf');
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: quicksand;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #EDF1FA; */
  /* background: linear-gradient(to top right, #005acf46 ,white,#005acf79); */
  background-repeat: no-repeat;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: black;
}

hr{
  border: none;
  background: rgb(231, 231, 231);
  width: 100%;
  height: 1px;
  opacity: 0.5;
}

