.home-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2em;
    padding: 5em 1em;
}

.home-inner-container{
    width: 80%;
    max-width: 900px;
}

@media only screen and (min-width: 10px){

}
@media only screen and (min-width: 768px){
    
}
@media only screen and (min-width: 1200px){
    
}